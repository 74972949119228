import React from 'react';
import './Education.css';
import Fade from 'react-reveal/Fade';

function Education() {
    return(
        <>
        <div className="education-console" id="education">
            <div className="education-wrapper">
                <Fade left>
                <div className="education-blurb-1">
                    <div className="education-box box-1">
                        <h2>Education <i class="fa-solid fa-graduation-cap"></i></h2>

                        <h3>University of North Texas</h3>
                        <h4>Bachelor's Degree</h4>
                        <p>Media Studies</p>

                    </div>

                    <div className="education-box box-2">
                        <h2>Languages <i class="fa-solid fa-quote-left"></i></h2>

                        <p>HTML / CSS</p>
                        <p>Javascript</p>
                        <p>Python</p>
                        <p>React</p>
                        <p>SQL</p>

                    </div>
                </div>
                </Fade>

                <Fade right>
                <div className="education-blurb-2">
                    <div className="education-box box-3">
                        <h2>Certifications <i class="fa-solid fa-award"></i></h2>

                        <h3>Data Analysis & Visualization</h3>
                        <h4><a target="_blank" href="https://www.credly.com/badges/0bbdd68e-b142-4674-8b96-6938aa961bdb/public_url" className="cert-link">Google</a></h4>
                    </div>

                    <div className="education-box box-4">
                        <h2>Tools <i class="fa-solid fa-chart-pie"></i></h2>

                        <p>BigQuery Sandbox</p>
                        <p>Google & Microsoft Suite</p>
                        <p>Salesforce</p>
                        <p>Tableau</p>
                        <p>Visual Studio Code</p>
                        
                    </div>
                </div>
                </Fade>
            </div>
        </div>
        </>
    )
}

export default Education;