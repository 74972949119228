import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';
import Fade from 'react-reveal/Fade';


function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const isValidEmail = email => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    };
    
    const submit = () => {
        if (name && email && message) {
            const serviceId = 'service_n4z5tla';
            const templateId = 'template_up0uwev';
            const userId = 'user_AlsgkFeZpSopAthDDy1K1';
            const templateParams = {
                name,
                email,
                message
            };

            emailjs.send(serviceId, templateId, templateParams, userId)
                .then(response => console.log(response))
                .then(error => console.log(error));

            setName('');
            setEmail('');
            setMessage('');
            setEmailSent(true);
            alert("Thanks!  I'll be in touch soon.");
        } else {
            alert("Oops!  You didn't enter all the fields. ");
        }
    };



    return (
        <>
        <div className="contact-console" id="contact">
            <div className="contact-wrapper">
            <Fade left>
            <div className="form-wrapper">
                <h1>Get in Touch!</h1>
                <form className="form">
                <label for="name">Please enter your full name:</label>
                    <input  
                        type="text"
                        name="name"
                        className="name"
                        tabIndex="1"
                        placeholder="Britney Spears"
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                    <label for="email">Email Address:</label>
                    <input 
                        type="email"
                        name="email"
                        id="email"
                        className="email"
                        placeholder="britney@britneyspears.com"
                        tabindex="2"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    
                    <label for="message"> Message: </label>
                    <textarea
                        placeholder=""
                        className="message"
                        name="message"
                        tabindex="3"
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                    />

                    <button onClick={submit} tabindex="4">Send</button>
                </form>
                </div>
            </Fade>
            </div>
        </div>
        </>
    )
}

export default Contact;