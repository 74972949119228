import React from 'react';
import './Footer.css';


function Footer() {
    return (
        <>
        <footer className="footer-console">
            <div className="footer-container">
                <a href="https://instagram.com/_dicapriho" target="_blank" className="social-links">
                    <i className="fab fa-instagram"></i>
                </a>

                <a href="https://linkedin.com/in/jordan-dorsey/" target="_blank" className="social-links">
                    <i className="fab fa-linkedin"></i>
                </a>

                <a href="https://twitter.com/_dicapriho" target="_blank" className="social-links">
                    <i className="fab fa-twitter"></i>
                </a>

                <a href="https://github.com/dicapriho" target="_blank" className="social-links">
                    <i className="fab fa-github"></i>
                </a>
            </div>
        </footer>
        </>
    )
}

export default Footer;