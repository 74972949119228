import React from 'react';
import Navigation from './components/Navigation';
import Welcome from './components/Welcome';
import Skills from './components/Skills';
import Contact from './components/Contact';
import Portfolio from './components/Portfolio';
import Process from './components/Process';
import Education from './components/Education';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <>
    <Navigation />
    <Welcome />
    <Skills />
    <Portfolio />
    <Process />
    <Education />
    <Contact />
    <Footer />
    </>
  );
}

export default App;

