import { Link } from 'react-scroll';
import './Welcome.css';


function Welcome() {
    return (
        <>
        <div className="welcome-console" id="welcome">
          <div className="welcome-wrapper">
              <div className="image-wrapper">
                  <div className="image-shadow">
                    <div className="image-border">
                        <Link
                            className="pic-link"
                            to="contact"
                            spy={false}
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                            <img src={process.env.PUBLIC_URL + "/images/welcome.png"} alt="Picture of Jordan Dorsey" preload="auto" />
                        </Link>
                    </div>
                  </div>
              </div>

                <div className="title">
                    <h1>Data Analyst & Front-End Developer</h1>
                </div>
          </div>
        </div>
        </>
    )
}

export default Welcome;