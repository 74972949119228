import React from 'react';
import './Portfolio.css';
import Fade from 'react-reveal/Fade';






function Portfolio() {
    return(
        <>
        <div className="portfolio-console" id="portfolio">
            <div className="portfolio-wrapper">
                <Fade left>
                    <a href="images/ts_analysis.pdf" target="_blank" className="tile-image"><img src={process.env.PUBLIC_URL + "/images/ts-analysis.jpg"} alt="Taylor Swift folklore" /></a>
                </Fade>

                <Fade right>
                    <a href="images/bellabeat.pdf" target="_blank" className="tile-image"><img src={process.env.PUBLIC_URL + "/images/bellabeatimage.png"} alt="Image of chart" /></a>
                </Fade>
            </div>
        </div>
        </>
    )
}

export default Portfolio;