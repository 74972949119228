import React from 'react';
import './Process.css';

import Fade from 'react-reveal/Fade';

function Process() {
    return (
        <>
        <div className="process-console">
            <div className="process-header">
                <h1>PROCESS</h1>
            </div>
            <Fade left>
            <div className="process-container">

                <div className="step-wrapper">
                    <div className="process-circle circle-1">  
                        <img src={process.env.PUBLIC_URL + "/images/define.png"} className="static" alt="book" preload="auto"></img>
                        <img src={process.env.PUBLIC_URL + "/images/define.gif"} className="active" alt="moving book"></img>
                    </div>

                    <div className="process-text">
                        <h2 className="process-name">Define</h2>
                        <p className="process-desc">Identify business problem <br /> or question that <br /> needs to be solved.</p>
                    </div>
                </div>

                <div className="step-wrapper">
                    <div className="process-circle circle-2">    
                    <img src={process.env.PUBLIC_URL + "/images/gather.png"} className="static" alt="pages" preload="auto"></img>
                        <img src={process.env.PUBLIC_URL + "/images/gather.gif"} className="active" alt="moving pages"></img>
                    </div>

                    <div className="process-text">
                        <h2 className="process-name">Gather</h2>
                        <p className="process-desc">Gather relevant and <br />current data from trusted sources. </p>
                    </div>
                </div>

                <div className="step-wrapper">
                    <div className="process-circle circle-3">    
                        <img src={process.env.PUBLIC_URL + "/images/clean.png"} className="static" alt="antibaterical spray" preload="auto"></img>
                        <img src={process.env.PUBLIC_URL + "/images/clean.gif"} className="active" alt="moving bacterial spray"></img>
                    </div>

                    <div className="process-text">
                        <h2 className="process-name">Clean</h2>
                        <p className="process-desc">Process data to ensure accuracy.</p>
                    </div>
                </div>
               
                <div className="step-wrapper">
                    <div className="process-circle circle-4">    
                        <img src={process.env.PUBLIC_URL + "/images/investigate.png"} className="static" alt="magnifying glass" preload="auto"></img>
                        <img src={process.env.PUBLIC_URL + "/images/investigate.gif"} className="active" alt="moving magnifying glass"></img>
                    </div>

                    <div className="process-text">
                        <h2 className="process-name">Investigate</h2>
                        <p className="process-desc">Analyze clean, collected data by <br /> running calculations <br /> and gathering statistics. </p>
                    </div>
                </div>
                
                <div className="step-wrapper">
                    <div className="process-circle circle-5">    
                        <img src={process.env.PUBLIC_URL + "/images/interpret.png"} className="static" alt="ebook reader icon" preload="auto"></img>
                        <img src={process.env.PUBLIC_URL + "/images/interpret.gif"} className="active" alt="ebook reader icon that moves"></img>
                    </div>

                    <div className="process-text">
                        <h2 className="process-name">Interpret</h2>
                        <p className="process-desc">Build inspiring visualizations <br /> to clearly present analysis to <br />various stakeholders.  </p>
                    </div>
                </div>

            </div>
            </Fade>
        </div>
        </>
    )
}

export default Process;