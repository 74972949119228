import React, { useState } from 'react';
import './Navigation.css';
import { Link } from 'react-scroll';
import Fade from 'react-reveal/Fade';


function Navigation () {
    const [ navbarOpen, setNavbarOpen ] = useState(false)
    const handleToggle = () => {
        setNavbarOpen(!navbarOpen)
    }

    const closeMenu = () => {
        setNavbarOpen(false)
    }
    
    return (
        <>
        <Fade top>
        <div className="nav-console">
            <div className="nav-wrapper">
                <h1>
                    <Link
                        className="nav-link"
                        to="welcome"
                        spy={false}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >
                    Jordan Dorsey
                    </Link>
                </h1>
                <ul className={`menuNav ${navbarOpen ? "showMenu" : "hidden"}`}>
                    <li >
                        <Link
                            className="nav-link"
                            to="contact"
                            spy={false}
                            smooth={true}
                            offset={-40}
                            duration={500}
                            onClick={() => closeMenu()}
                            exact
                        >
                        Contact
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="nav-link"
                            to="education"
                            spy={false}
                            smooth={true}
                            offset={-100}
                            duration={500}
                            onClick={() => closeMenu()}
                            exact
                        >
                        Education
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="nav-link"
                            to="skills"
                            spy={false}
                            smooth={true}
                            offset={-40}
                            duration={500}
                            onClick={() => closeMenu()}
                            exact
                        >
                        Skills
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="nav-link"
                            to="portfolio"
                            spy={false}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            onClick={() => closeMenu()}
                            exact
                        >
                        Portfolio
                        </Link>
                    </li>
                </ul>
                <i className={navbarOpen ? "fa-solid fa-xmark" : "fa-solid fa-bars"} onClick={handleToggle}></i>
            </div>
        </div>
        </Fade>
        </>
    )
}

export default Navigation;