import './Skills.css';
import Fade from 'react-reveal/Fade';


function Skills() {
    return (
        <>
        <div className="skills-console" id="skills">
            <div className="skills-wrapper">
                <Fade right>
                <div className="quote">
                    <h2>My philosophy is, basically this: <br /> 
                        And this is something that I live by. <br />
                        And I always have. <br />
                        And I always will. <br />
                        Don't ever, for any reason, <br />
                        do anything to anyone. For any reason, <br />
                        ever - no matter what. No matter where <br />
                        or who you are with, or where you are going <br />
                        or where you have been. <br />
                        Ever.<br />
                        <br />
                        -Michael Scott
                    </h2>
                </div>
                </Fade>

                <Fade left>
                <div className="skills-container">
                    <div className="skill-card">
                        <div className="skill-card-overlay">
                            <h2>Analytical Thinking<i class="far fa-lightbulb"></i></h2>
                            <p>A passion for transforming data into stories through rich and exciting visuals.
                            Proficient in validating, analyzing and interpreting data. </p>
                        </div>
                    </div>
                    
                    <div className="skill-card">
                        <div className="skill-card-overlay">
                        <h2>Communication & Collaboration<i class="far fa-comments"></i></h2>
                            <p>3+ years of project management experience, mastering the ability
                            to organize multi-department teams in fast-paced environments. </p>
                        </div>
                    </div>

                    <div className="skill-card">
                        <div className="skill-card-overlay">
                            <h2>Developer Skills<i class="fas fa-code"></i></h2>
                            <p>Working knowledge of HTML, CSS and Javascript.  
                            Deep love for building responsive web applications using React. </p>
                        </div>
                    </div>                    
                </div>
                </Fade>
            </div>
        </div>
        </>
    )
}

export default Skills;